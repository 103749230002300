import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Box, Transition } from './containers';
import { Toolbar } from './navigation';
import theme from '../theme';

const GlobalStyle = createGlobalStyle`
    body, html {
        margin: 0;
        padding: 0;
        font-family: ${props => props.theme.fonts.body};
    }
    a {
        text-decoration: none;
    }
    li {
        list-style-type: none;
    }
    @media (any-pointer: fine) {
        ::-webkit-scrollbar {
            width: 0.25em;
        }
    }

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.secondary};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.colors.primary};
    }

    ::-webkit-scrollbar-track {
        background: #fff;
    }
`

const Layout = ({children}) => (
<ThemeProvider theme={theme}>
    <>
    <Box id="page">
        <Transition>
            <Toolbar />
        </Transition>
        <Transition height="100%" justifyContent="center" pt={[4, 0]} p={[0,3]} position="relative" alignSelf="start">
            {children}
        </Transition>
    </Box>
    <GlobalStyle />
    </>
</ThemeProvider>
);

export default Layout;