/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./static/fonts.css";

import React from 'react';
import Layout from './src/components/layout';

let lastPageContent;
const AnimationValues = {
  DURATION: 300,
  ENTER_LIST: ['slide-up', 'enter-left', 'fade-in'],
  EXIT_LIST: ['exit-right']
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onPreRouteUpdate = ({location, prevLocation}) => {
  lastPageContent = document.getElementById('content');
};

export const onRouteUpdate = ({location, prevLocation}) => {
  if (prevLocation !== null && prevLocation.pathname !== location.pathname) {
    let container = document.getElementById('container');
    let content = document.getElementById('content');
    let swapNodes = (container, newNode, oldNode) => {
      if (container.contains(oldNode)) {
        container.replaceChild(newNode, oldNode);
      }
    }
  
    if (lastPageContent) {
      lastPageContent.classList.remove(...AnimationValues.ENTER_LIST);
      lastPageContent.classList.add(...AnimationValues.EXIT_LIST);
      swapNodes(container, lastPageContent, content);
      setTimeout(() => {
        swapNodes(container, content, lastPageContent);
      }, AnimationValues.DURATION);
    }
  }
};