import styled from 'styled-components';
import { background, border, color, flexbox, grid, gridGap, layout, position, space, shadow, system, textAlign } from "styled-system";

export const Box = styled.div`
    ${background}
    ${border}
    ${color}
    ${flexbox}
    ${layout}
    ${position}
    ${shadow}
    ${space}
    ${grid}
    box-sizing: border-box;
    min-width: 0;
    text-transform: ${props => props.textTransform};
    ${system({
        transform: true,
        transition: true,
    })}
`;

export const Flex = styled(Box)`
    ${flexbox}
    ${grid}
    display: flex;
`;

export const Grid = styled(Box)`
    ${gridGap}
    ${flexbox}
    ${system({
        gridAutoRows: {
            property: 'gridAutoRows',
            scale: 'gridRows',
        },
        gridTemplateColumns: {
            property: 'gridTemplateColumns',
            scale: 'gridColumns'
        }
    })}
`;

Grid.defaultProps = {
    display: 'grid'
}

export const List = styled.ul`
    ${color}
    ${position}
    ${flexbox}
    ${layout}
    ${space}
    ${textAlign}
    list-style-type: none;
`;

List.defaultProps = {
    margin: '0px',
    padding: '0px'
}

export const ShadowSmall = styled(Box)`
    box-shadow: ${props => props.theme.shadows.small};
`;

export const ShadowLarge = styled(Box)`
    box-shadow: ${props => props.theme.shadows.large};
`;

export const Spacer = styled.div(space);

export const Transition = styled(Flex)`
    --fade: 500ms;
    --long: 1000ms;
    --curve: ease-out;

    @keyframes fade-in {
        from { 
            opacity: 0;
        }
        to   {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from { 
            opacity: 1;
        }
        to   {
            opacity: 0;
        }
    }


    @keyframes slide-down {
        from { 
            opacity: 0;
            transform: translate(0%, -100%); 
        }
        to   {
            opacity: 1;
            transform: translate(0%, 0%); 
        }
    }
    @keyframes enter-left {
        from { 
            opacity: 0;
            transform: translate(-100%, 0%); 
        }
        to   {
            opacity: 1;
            transform: translate(0%, 0%); 
        }
    }
    @keyframes slide-up {
        from { 
            opacity: 0;
            transform: translate(0%, 100%); 
        }
        to   {
            opacity: 1;
            transform: translate(0%, 0%); 
        }
    }
    @keyframes exit-right {
        from { 
            opacity: 1;
            transform: translate(0%, 0%); 
        }
        to   {
            opacity: 0;
            transform: translate(100%, 0%); 
        }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        .fade-in {
            animation: fade-in var(--fade);
        }
        .enter-left {
            animation: enter-left var(--curve) var(--long);
        }
        .exit-right {
            animation: exit-right ease-out var(--long);
            animation-fill-mode: forwards;
        }
    }

    
    @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
        .fade-in, .enter-left {
            animation: fade-in linear var(--fade);
        }

        .exit-right {
            animation: fade-out linear var(--fade);
        }
    }

    .slide-up {
        animation: slide-up var(--curve) var(--long);
    }
`;

export const Wrapper = styled(Transition).attrs(() => ({ id: 'container'}))`
    max-width: 1440px;
    justify-content: center;
    width: 100%;
`;