export default {
    breakpoints: ['40em', '52em', '80em'],
    colors: {
        primary: '#222222',
        secondary: '#969696',
        secondaryLight: '#E6E6E6',
        accent: '#ed3d29',
        accentLight: 'rgba(238, 60, 40, 0.5)',
        imageGradient: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.8) 100.00%)'
    },
    gridRows: [ '320px', 'minmax(300px, 1fr)', 'minmax(300px, 400px)', '400px'],
    gridColumns: [ '320px', 'repeat(2, minmax(275px, 1fr))', 'repeat(2, minmax(300px, 400px))', 'repeat(3, minmax(300px, 400px))'],
    fonts: {
        body: 'Montserrat, NotoSans, sans-serif',
        display: 'Big John, Spartan, Poppins, serif',
    },
    fontSizes: [10, 14, 18, 24, 36, 48, 72],
    fontWeights: ['normal', 'bold'],
    letterSpacings: {
        nav: '0.09rem'
    },
    shadows: {
        insetTop: '0px 8px 8px #000 inset',
        insetBottom: '0px -8px 8px #000',
        small: '0px 12px 12px rgba(159, 159, 159, 0.25)',
        large: '0px 24px 24px rgba(159, 159, 159, 0.25)'
    },
    sizes: {
        height: {
            preview: '300px'
        },
        width: {
            preview: '300px',
            menu: '250px'
        }
    },
    textTransform: {
        none: 'none',
        uppercase: 'uppercase',
    }
}