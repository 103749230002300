import  React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from 'styled-components';
import { borderRadius, color, flexbox, layout, position, space, system} from "styled-system";
import { Box, Flex, List } from "./containers";
import css from '@styled-system/css'
import Logo from '../images/logo.png';
import theme from '../theme';

const Nav = styled.nav`
    ${flexbox}
    ${layout}
    ${space}
    ${position}

    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .top-nav {
        li {  
            .item-label > a {
                color: ${props => props.theme.colors.secondary};
            }
            .item-label-hover {
                overflow: hidden;
                transition: width 200ms ease-in-out;
                width: 0%;
            }
            .item-label-hover, .item-label-hover > a {
                color: ${props => props.theme.colors.accent};
            }

            .item-label-hover:hover, 
            .item-label-hover:focus,
            .item-label:hover ~ .item-label-hover,
            .item-label:focus ~ .item-label-hover {
                width: 100%;
            }
        }

        font-family: ${props => props.theme.fonts.display};
        text-transform: uppercase;
        letter-spacing: ${props => props.theme.letterSpacings.nav};
        ${system({
            textTransform: true,
        })}
    }

    .menu-container {
        transition: box-shadow 300ms, max-height 300ms;
    }
`;

const ListItem = styled.li`
    ${color}
    ${space}
    ${layout}
    ${borderRadius}
    ${position}
    span {
        cursor: pointer;
    }
    ${system({
        transform: true,
        transition: true
    })}
`;

const Hamburger = styled(Flex)`
    --size: 24px;
    --dy: 10.5px;

    width: var(--size);
    height: var(--size);
    span {
        background-color: ${props => props.theme.colors.primary};
        border-radius: 3px;
        width: 100%;
        height: 3px;
        opacity: 1;
        transition: transform 200ms ease-out, opacity 100ms linear;
    }

    ${props => props.toggle && `
    span:nth-of-type(even) {
        opacity: 0;
        transform: translate(100%, 0%);
    }
    #first {
        transform: translateY(var(--dy)) rotate(135deg); 
    }
    #last {
        transform: translateY(calc(-1 * var(--dy))) rotate(-135deg); 
    }
    `}
`

export const HamburgerIcon = ({toggle}) => {
    return <Hamburger toggle={toggle} flexDirection="column" justifyContent="space-between">
    <span id="first"></span>
    <span></span>
    <span id="last"></span>
</Hamburger>
}

const ToolbarItem = ({title, path}) => 
{
    const activeStyle = {
        color: 'white',
        background: `${theme.colors.accent}`,
        display: 'block',
        width: '100%'
    };

    return <ListItem key={title} mx={[0, 4]}>  
        <Box position="relative">
            <Box className="item-label">
                <Link activeStyle={activeStyle} to={path}>
                    <Box px={4} py={[4, 3]}>
                        <span>{title}</span>
                    </Box>
                </Link>
            </Box>
            <Box className="item-label-hover" display={["none", "none", "block"]} position="absolute" top={0} zIndex={99}>
                <Link activeStyle={activeStyle} to={path}>
                    <Box px={4} py={[4, 3]}>
                        <span>{title}</span>
                    </Box>
                </Link>
                <Box bg="accent" height="3px" width="100%" />
            </Box>
        </Box>
    </ListItem>;
}


export const Toolbar = (props) => {
    const data = useStaticQuery(graphql`
    query Categories {
        content: allContentfulProject {
            categories: group(field: category___project___category___title) {
                title: fieldValue
            }
        }
    }
    `);

    const [toggle, setToggle] = useState(false);

    return (
    <Nav className="slide-down" width="100%">
        <Flex bg="white" flexDirection={["column", "column", "row"]} alignItems={["stretch", "stretch", "center"]} justifyContent="space-between" position="relative">
            <Flex alignItems="center" bg="white" justifyContent={["space-between", "space-between", "flex-start"]} p={3} width={["100%", "100%", "auto"]}>
                <Link to="/"><img src={Logo} alt="L Fried" css={css({width: [100,150]})}/></Link>
                <Box display={['block', 'block', 'none']} mr={0} onClick={() => setToggle(!toggle)}>
                    <HamburgerIcon toggle={toggle} />
                </Box>
            </Flex>
            <Flex className={`top-nav menu-container`} boxShadow={toggle ? ["insetTop", "insetTop", "initial"] : "none"} bg={["primary", "primary", "initial"]} flexDirection={["column", "column", "row"]} maxHeight={toggle ? ["500px", "500px", "none"] : ["0px", "0px", "none"]}
            overflow="hidden" position="relative">
                <Box p={3}>
                    <List display="flex" flexDirection={["column", "column", "row"]} 
                    justifyContent="justify-around">
                    {data.content.categories.map(({ title }) => 
                        <ToolbarItem key={title} title={title} path={`/${title.toLowerCase()}`}/>
                    )}
                        <ToolbarItem key={"about"} title={"About"} path={`/about`}/>
                    </List>
                </Box>
                <Box display={["block, block, none"]} position="absolute" width="100%" mb={-2} pb={2} boxShadow={toggle ? ["insetBottom", "insetBottom", "initial"] : "none"} bottom={0} />
            </Flex>
        </Flex>
    </Nav>
    );
}
